<template>
    <div>
        <div class="SecondTitle">
            <span>
                {{ $t("enterNumber.secondTitle") }}
            </span>
        </div>
        <div class="tips">
            <span>
                {{ $t("enterNumber.tips") }}
            </span>
        </div>

        <van-form :onChange="saveInfo()">
            <div style="margin-top: 15px">
                <div style="width:25%;display: inline-block;vertical-align: middle;margin: 0 auto">
                    <van-image style="vertical-align: middle;float: left" :src="imagePath"/>
                </div>
                <div style="width:5%;display: inline-block;vertical-align: middle;margin: 0 auto">
                    <div style="width: 1px;float:right;height: 20px; background: #000000;"></div>
                </div>
                <div style="width:70%;display: inline-block;vertical-align: middle;margin: 0 auto">
                    <van-field
                            type="tel"
                            v-model="phoneNumber"
                            center
                            :placeholder="this.$t('enterNumber.phonePlaceHolder')"
                            @focus="selectDate()"
                            :rules="[{ pattern, trigger:'onBlur',message: this.$t('enterNumber.phoneRuleMessage') }]"

                    />
                    <van-number-keyboard
                            v-model="phoneNumber"
                            :show="showKeyboard"
                            @blur="showKeyboard = false"
                    />
                </div>
            </div>

            <van-divider :style="{ borderColor: '#000000', margin: '0' }"/>
            <div class="small-tips">
                <span>
                    {{ $t("enterNumber.smallTips") }}
                </span>
            </div>
            <div class="second-tips">
                    <div>
                        <input  class="agreeCheckBox" type="checkbox" v-model="agreeChecked">
                    </div>
                    <div class="agreeStatement">
                        {{ $t("registration.statementUnderGender1") }}{{ $t("registration.brandName") }}{{ $t("registration.possessive") }} 
                        <!-- TODO: Add new brand p&p -->
                        <a v-if="this.brandName=='bobbibrown'" href= 'https://www.bobbibrown.com.sg/privacy-policy' >{{ $t("registration.privacyPolicy") }}</a>
                        <a v-else-if="this.brandName=='esteelauder'" href= 'https://www.esteelauder.com.sg/privacy' >{{ $t("registration.privacyPolicy") }}</a>
                        <a v-else-if="this.brandName=='lamer'" href= ' https://www.lamer.com.sg/customer-service-privacy-policy' >{{ $t("registration.privacyPolicy") }}</a>
                        <a v-else-if="this.brandName=='origins'" href= ' https://www.origins.com.sg/customer-service-privacy-policy' >{{ $t("registration.privacyPolicy") }}</a>
                        <a v-else-if="this.brandName=='labseries'" href= ' https://www.labseries.com.sg/customer-service-privacy-policy' >{{ $t("registration.privacyPolicy") }}</a>
                        <a v-else href= 'https://www.esteelauder.com.sg/privacy' >{{ $t("registration.privacyPolicy") }}</a>

                        {{$t("registration.period") }}
                    </div>
                </div>
            <van-button
                    :disabled=checkPhone()
                    size="large"
                    round
                    native-type="submit"
                    class="button-getotp"
                    @click="toOtp"
            >
                {{ $t("enterNumber.getOTP") }}
            </van-button>
        </van-form>
    </div>
</template>

<script>
    import {
        Form,
        DropdownMenu,
        DropdownItem,
        Divider,
        Button,
        Field,
        NumberKeyboard,
        PasswordInput,
        Toast
    } from 'vant';
    import {Image as VanImage} from 'vant';
    import {generateOtp} from '@/api/eformApi'
    export default {
        name: "EnterNumber",
        components: {
            [Form.name]: Form,
            [NumberKeyboard.name]: NumberKeyboard,
            [PasswordInput.name]: PasswordInput,
            [VanImage.name]: VanImage,
            [DropdownItem.name]: DropdownItem,
            [DropdownMenu.name]: DropdownMenu,
            [Divider.name]: Divider,
            [Button.name]: Button,
            [Field.name]: Field
        },
        data() {
            return {
                phoneNumber: '',
                linkToken:'',
                showKeyboard: false,
                imagePath:'',
                pattern: /^$|^([689]{1})+([0-9]{7})$/,
                sgPattern: /^$|^([689]{1})+([0-9]{7})+$/,
                thPattern: /^$|^([689]{1})+([0-9]{7})+$/,
                myPattern: /^(0[689]{1})+([0-9]{8})+$/,
                areaCode: 66,
                agreeChecked: false,
                brandName:'',
                country:'',
                countryPath:''
            };
        },

        // beforeCreated() {
        //     console.log(this.$route.params.countryPath)
        //     this.imagePath = require('/public/img/'+this.$route.params.countryPath+'-areacode.png')
        // },
        created(){
            // this.imagePath = /public/img/${localStorage.getItem('countryPath')}-areacode.png
            this.imagePath = require('/public/img/'+this.$route.params.countryPath+'-areacode.png')
            if(this.$route.query.token&&this.$route.query.redirect_url&&this.$route.query.seller_id){
            // if(this.$route.query.token&&this.$route.query.redirect_url){
                // this.linkToken=this.$route.query.token
                localStorage.setItem('link_token',this.$route.query.token)
                sessionStorage.setItem('link_token',this.$route.query.token)
                sessionStorage.setItem('redirect_url',this.$route.query.redirect_url+'&seller_id='+this.$route.query.seller_id)
                localStorage.removeItem('enterNumberInfo')
                this.$router.push(this.$route.path)
            }
            // this.$root.$i18n.locale = this.$route.params.lang
            // this.$root.$i18n.locale = this.$route.params.lang
            this.checkBrandName()
            localStorage.setItem('country', this.$route.params.lang)
            this.countryPath = this.$route.params.countryPath
            localStorage.setItem('countryPath',this.$route.params.countryPath)
            
            switch(localStorage.getItem('countryPath')){
                case 'sg':
                    this.areaCode = 65
                    this.pattern = this.sgPattern
                    break;
                case 'th':
                    this.areaCode = 66
                    this.pattern = this.thPattern
                    break;
                default:
                    this.areaCode = 65
                    this.pattern = this.myPattern
                    break;
            }
            if(localStorage.getItem('enterNumberInfo')){
                this.phoneNumber = localStorage.getItem('enterNumberInfo')
            }
        },
        mounted() {
        },
        methods: {
            selectDate(){
                // document.activeElement.blur();
                // this.showKeyboard = true
            },
            toOtp() {
                sessionStorage.setItem('phoneNumber',this.areaCode+this.phoneNumber)
                console.log( this.$route.params.countryPath)
                generateOtp({
                    // TODO: replace with real phone number
                    phoneno: this.areaCode + this.phoneNumber,
                    // phoneno: 8613272605864,
                    country: this.$route.params.countryPath,
                    brandName: this.brandName,
                }).then((res)=>{
                    if(res.success){
                        console.error(res.data.request_id)
                        if(this.countryPath=='th'){
                            sessionStorage.setItem('th_request_id',res.data.request_id)
                        }
                        localStorage.setItem
                        Toast.success(this.$t('enterNumber.toastSuccess'))
                        this.$router.push({
                            name: 'EnterOpt',
                            params: {
                                //TODO: replace with real phone number
                                phoneNumber: this.areaCode+this.phoneNumber,
                                // phoneNumber: 8613272605864,
                                country: localStorage.getItem('country'),
                                brandName: this.brandName
                            }
                        })
                    }else{
                        console.log(res.success)
                        Toast.fail(res.data)
                        // Toast.fail(this.$t('enterNumber.toastFail'))
                        //TODO: mock need to be deleted
                        // this.$router.push({
                        //     name: 'EnterOpt',
                        //     params: {
                        //         phoneNumber: this.areaCode+this.phoneNumber,
                        //         country: localStorage.getItem('countryPath')
                        //     }
                        // })
                    }
                })
            },
            checkPhone(){
                switch (localStorage.getItem('countryPath')){
                    case 'sg':
                        if(this.phoneNumber.length === 0 || !this.sgPattern.test(this.phoneNumber)||!this.agreeChecked){
                            return true
                        }else{
                            return false
                        }
                    case 'th':
                        if(this.phoneNumber.length === 0 || !this.thPattern.test(this.phoneNumber)||!this.agreeChecked){
                            return true
                        }else{
                            return false
                        }
                    case 'my':
                        if(this.phoneNumber.length === 0 || !this.myPattern.test(this.phoneNumber)||!this.agreeChecked){
                           return true
                        }else{
                            return false
                        }
                    default:
                        return true
                }
            },
            /**
             * Get brand name and country lang according hostname and request lang
             */
            checkBrandName(){
                let brandList = ['esteelauder','bobbibrown','lamer','labseries','origins']
                this.brandName = location.hostname.split('.')[1]
                this.country = location.hostname.split('.').pop();
                this.$root.$i18n.locale = this.$route.params.lang
                // this.$root.$i18n.locale =this.$route.params.lang+"_"+this.brandName
                if(!brandList.includes(this.brandName)){
                    this.brandName='origins'
                }
                if(location.hostname.split('.')[1]!='esteelauder'){
                    this.$root.$i18n.locale = this.$route.params.lang+"_"+this.brandName
                }
                localStorage.setItem('brandName',this.brandName)
            },
            saveInfo(){
                 localStorage.setItem('enterNumberInfo',this.phoneNumber)
            }
        }
    }
</script>

<style scoped>

    >>> .van-popup--top{
        width: 25%;
        padding-left: 16px
    }
    .button-getotp{
        background: black;
        color: white;
        border-radius: 7px;
        margin-top: 4%;
    }
    >>> .van-password-input__item {
        border-radius: 5px
    }
</style>
